import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import HeroStats from '../components/HeroStats';
import HeroAudience from '../components/HeroAudience';
import Lead from '../components/Lead';
import MotivationalSection from '../components/MotivationalSection';
import PopularPagesSection from '../components/PopularPagesSection';
import EventDataSection from '../components/EventDataSection';
import ComparativeProgressSection from '../components/ComparativeProgressSection';

const ReportDashboard = () => {
  return (
    <div className="w-full bg-gray-50 text-gray-800">
      <Header />

      {/* Hero Section */}
      <HeroSection />

      {/* Key Stats */}
      <div className="px-8 py-12">
        <HeroStats />
      </div>

      {/* Audience Map */}
      <div className="px-8 py-12">
        <HeroAudience />
      </div>


      {/* Lead Generation */}
      <div className="px-8 py-12">
        <Lead />
      </div>

      {/* Popular Pages Section */}
      <div className="px-8 py-12">
        <PopularPagesSection />
      </div>

            {/* Popular Pages Section */}
            <div className="px-8 py-12">
        <EventDataSection />
      </div>

           {/* Comparacion de mes */}
           <div className="px-8 py-12">
        <ComparativeProgressSection />
      </div>

      {/* Motivational Section */}
      <div className="px-8 py-12">
        <MotivationalSection />
      </div>

      <Footer />
    </div>
  );
};

export default ReportDashboard;
