import React, { useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

// Datos
const languageData = [
  { name: 'Español', value: 24 },
  { name: 'Inglés', value: 58 },
];

const searchEngineData = [
  { name: 'Google', value: 33 },
  { name: 'Bing', value: 12 },
  { name: 'Biztools', value: 3 },
  { name: 'DuckDuckGo', value: 2 },
  { name: 'Ecosia', value: 1 },
  { name: 'Facebook', value: 1 },
  { name: 'l.facebook', value: 1 },
];

const deviceData = [
  { name: 'Desktop', value: 64 },
  { name: 'Mobile', value: 24 },
];

const cityData = [
  { name: 'Ashburn', value: 28 },
  { name: 'San Jose', value: 11 },
  { name: 'Hyderabad', value: 3 },
  { name: 'Carrington', value: 2 },
  { name: 'Los Angeles', value: 2 },
  { name: 'New York', value: 2 },
  { name: 'Altoona', value: 1 },
];

const osData = [
  { name: 'Windows', value: 54 },
  { name: 'iOS', value: 18 },
  { name: 'Macintosh', value: 8 },
  { name: 'Android', value: 6 },
  { name: 'Chrome OS', value: 4 },
  { name: 'Linux', value: 4 },
];

const browserData = [
  { name: 'Chrome', value: 67 },
  { name: 'Edge', value: 10 },
  { name: 'Safari', value: 9 },
  { name: 'Android WebView', value: 1 },
  { name: 'Samsung Internet', value: 1 },
];

const COLORS = ['#1e3a8a', '#4338ca', '#6366f1', '#4ade80', '#facc15', '#f87171', '#93c5fd'];

const AudienceInsights = () => {
  const [activeSection, setActiveSection] = useState('language');

  const sections = [
    { key: 'language', title: '¿En qué idioma te buscan?', data: languageData, type: 'pie' },
    { key: 'searchEngine', title: '¿Cómo te buscan?', data: searchEngineData, type: 'bar' },
    { key: 'device', title: '¿Desde qué dispositivos?', data: deviceData, type: 'pie' },
    { key: 'city', title: '¿Cuáles son las ciudades que más te buscan?', data: cityData, type: 'bar' },
    { key: 'os', title: '¿Qué sistemas operativos usan?', data: osData, type: 'bar' },
    { key: 'browser', title: '¿Qué navegadores usan?', data: browserData, type: 'bar' },
  ];

  const renderChart = (section) => {
    if (section.type === 'pie') {
      return (
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={section.data}
              cx="50%"
              cy="50%"
              outerRadius={100}
              dataKey="value"
              label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
            >
              {section.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      );
    } else if (section.type === 'bar') {
      return (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={section.data} margin={{ top: 20, right: 20, left: 10, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill="#6366f1" />
          </BarChart>
        </ResponsiveContainer>
      );
    }
  };

  return (
    <section className="bg-indigo-50 py-16 px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-indigo-900 mb-10">Insights de tu Audiencia</h2>

        {/* Botones de navegación */}
        <div className="flex justify-center mb-8 space-x-4">
          {sections.map((section) => (
            <button
              key={section.key}
              onClick={() => setActiveSection(section.key)}
              className={`px-4 py-2 rounded-lg font-semibold shadow-md ${
                activeSection === section.key
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              {section.title}
            </button>
          ))}
        </div>

        {/* Gráfico actual */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h3 className="text-xl font-semibold text-indigo-800 mb-4">
            {sections.find((section) => section.key === activeSection)?.title}
          </h3>
          {renderChart(sections.find((section) => section.key === activeSection))}
        </div>
      </div>
    </section>
  );
};

export default AudienceInsights;
