import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

// Datos previos (países del mes anterior)
const previousCountryData = ['United States', 'Costa Rica'];

// Datos actualizados de visitas por país
const countryData = [
  { name: 'United States', value: 35 },
  { name: 'Costa Rica', value: 13 },
  { name: 'Canada', value: 1 },
  { name: 'Mexico', value: 1 },
  { name: 'Peru', value: 1 },
];

// Colores para cada país
const COLORS = ['#1e3a8a', '#4338ca', '#6366f1', '#4ade80', '#facc15'];

// Detectar nuevos países
const newCountries = countryData
  .filter((country) => !previousCountryData.includes(country.name))
  .map((country) => country.name);

const HeroAudience = () => {
  return (
    <section className="bg-white py-10 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl sm:text-3xl font-bold text-center text-gray-800 mb-4 sm:mb-6">Impacto Global</h2>
        <p className="text-base sm:text-lg text-center text-gray-600 mb-8 sm:mb-12">
          Su sitio web ha sido visto por personas en diversas partes del mundo. A continuación, se detallan los datos de su audiencia.
        </p>

        {/* Mostrar mensaje de nuevos países */}
        {newCountries.length > 0 && (
          <div className="bg-green-50 text-green-700 p-4 rounded-lg text-center mb-8">
            <p className="text-lg font-semibold">
              ¡Haz conquistado un nuevo país! {newCountries.join(', ')}. Sigue adelante 🚀
            </p>
          </div>
        )}

        {/* Sistema de 2 columnas: Gráfico a la izquierda, información a la derecha */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-12 items-center">
          {/* Columna izquierda: Gráfico de pastel */}
          <div className="flex justify-center w-full">
            <ResponsiveContainer width="100%" height={250} min-width={200} min-height={200} max-width={400} max-height={400}>
              <PieChart>
                <Pie
                  data={countryData}
                  cx="50%"
                  cy="50%"
                  innerRadius={50}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                >
                  {countryData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>

          {/* Columna derecha: Descripción y lista de países */}
          <div className="space-y-4 sm:space-y-6">
            <h3 className="text-lg sm:text-xl font-semibold text-gray-800">¿Cómo se calculan estos datos demográficos?</h3>
            <p className="text-sm sm:text-base text-gray-600">
              Los datos demográficos se obtienen mediante el análisis de la actividad de los usuarios en su sitio web. Se recopila información como la ubicación geográfica, el idioma y otros factores relevantes para comprender mejor a su audiencia. Esta información se utiliza para adaptar el contenido y las estrategias de marketing a las necesidades y preferencias de los visitantes.
            </p>
            {countryData.map((country, index) => (
              <div key={index} className="text-center sm:text-left">
                <p className="text-xl font-bold text-indigo-700">{country.name}</p>
                <p className="text-gray-600">{country.value} visitas</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroAudience;
