import React from 'react';
import { useSpring, animated } from '@react-spring/web';

const performanceData = [
  { name: 'Rendimiento', value: 96, description: 'Mide la rapidez de carga de tu sitio web y su capacidad de respuesta.' },
  { name: 'Accesibilidad', value: 98, description: 'Evalúa si todos los usuarios, incluyendo aquellos con discapacidades, pueden navegar por el sitio.' },
  { name: 'Prácticas recomendadas', value: 96, description: 'Se refiere a la implementación de buenas prácticas de desarrollo que mejoran la experiencia del usuario.' },
  { name: 'SEO', value: 93, description: 'Determina si el sitio está optimizado para aparecer en los resultados de búsqueda de Google y otros motores.' },
];

// Calcular promedio de calificaciones
const averageScore = Math.round(
  performanceData.reduce((acc, metric) => acc + metric.value, 0) / performanceData.length
);

const EventDataSection = () => {
  // Animación para la barra de promedio
  const progressAnimation = useSpring({ width: `${averageScore}%`, from: { width: '0%' } });

  // Animaciones individuales para cada métrica
  const animatedValues = [
    useSpring({ from: { number: 0 }, number: performanceData[0].value, config: { duration: 1000 } }),
    useSpring({ from: { number: 0 }, number: performanceData[1].value, config: { duration: 1000 } }),
    useSpring({ from: { number: 0 }, number: performanceData[2].value, config: { duration: 1000 } }),
    useSpring({ from: { number: 0 }, number: performanceData[3].value, config: { duration: 1000 } }),
  ];

  return (
    <section className="bg-white py-16 px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">Otros Datos de Interés</h2>
        <p className="text-lg text-center text-gray-600 mb-12">
          Estos datos muestran el rendimiento actual de tu sitio web en métricas clave de Google. Mantener una buena
          calificación en cada uno de estos aspectos es esencial para asegurar una experiencia de usuario óptima y
          mejorar el posicionamiento en motores de búsqueda.
        </p>

        {/* Círculos animados con valores de rendimiento */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 justify-items-center mb-12">
          {performanceData.map((metric, index) => (
            <div key={index} className="flex flex-col items-center">
              <animated.div
                style={{ width: '96px', height: '96px' }}
                className="flex items-center justify-center w-24 h-24 bg-indigo-600 rounded-full text-white text-3xl font-bold"
              >
                {animatedValues[index].number.to((number) => Math.round(number))}
              </animated.div>
              <p className="text-center text-gray-800 font-semibold mt-2">{metric.name.replace('_', ' ')}</p>
              <p className="text-center text-gray-600 text-sm mt-1">{metric.description}</p>
            </div>
          ))}
        </div>

        {/* Barra de progreso animada con promedio de calificación */}
        <div className="text-center">
          <p className="text-lg font-semibold text-gray-800 mb-4">Promedio de Calificación: {averageScore}</p>
          <div className="relative w-full bg-gray-200 h-4 rounded-lg overflow-hidden max-w-xl mx-auto">
            <animated.div
              style={progressAnimation}
              className="absolute top-0 left-0 h-4 bg-indigo-600"
            />
          </div>
          <div className="flex justify-between text-gray-600 text-sm mt-2 max-w-xl mx-auto">
            <span>0</span>
            <span>100</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventDataSection;
