import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Datos actualizados de generación de leads por fuente
const leadData = [
  { name: 'Directo', value: 64 },
  { name: 'Búsqueda Orgánica', value: 11 },
  { name: 'Referencia', value: 4 },
  { name: 'Redes Sociales', value: 3 },
];

// Datos para el gráfico
const chartData = [
  { name: 'Leads', Directo: 64, 'Búsqueda Orgánica': 11, Referencia: 4, 'Redes Sociales': 3 },
];

// Colores para cada fuente
const COLORS = ['#1e3a8a', '#4338ca', '#6366f1', '#4ade80'];

const Lead = () => {
  return (
    <section className="bg-white py-16 px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">Generación de Leads</h2>
        <p className="text-lg text-center text-gray-600 mb-12">
          A continuación, se presenta un análisis de las fuentes de generación de leads y cómo se recopilan estos datos.
        </p>

        {/* Sistema de 2 columnas: Gráfico de barras apiladas a la izquierda, información a la derecha */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Columna izquierda: Gráfico de barras apiladas */}
          <div className="flex justify-center">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={chartData} layout="vertical" margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis type="category" dataKey="name" />
                <Tooltip />
                <Legend />
                <Bar dataKey="Directo" stackId="a" fill={COLORS[0]} name="Directo" />
                <Bar dataKey="Búsqueda Orgánica" stackId="a" fill={COLORS[1]} name="Búsqueda Orgánica" />
                <Bar dataKey="Referencia" stackId="a" fill={COLORS[2]} name="Referencia" />
                <Bar dataKey="Redes Sociales" stackId="a" fill={COLORS[3]} name="Redes Sociales" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          {/* Columna derecha: Descripción y lista de fuentes */}
          <div className="space-y-6">
            <h3 className="text-xl font-semibold text-gray-800">¿Cómo se recopilan estos datos de leads?</h3>
            <p className="text-gray-600">
              Los datos de generación de leads se obtienen mediante el seguimiento de las interacciones de los usuarios con su sitio web y otras plataformas digitales. Se analizan las fuentes de tráfico, las conversiones y otras métricas clave para identificar de dónde provienen los leads y cómo interactúan con su contenido. Esta información es esencial para optimizar las estrategias de marketing y mejorar la captación de clientes potenciales.
            </p>
            {leadData.map((source, index) => (
              <div key={index} className="text-center lg:text-left">
                <p className="text-2xl font-bold text-indigo-700">{source.name}</p>
                <p className="text-gray-600">{source.value} leads</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Lead;
