import React, { useState } from 'react';

const MotivationalSection = () => {
  const [currentMonth, setCurrentMonth] = useState(4);

  const timelineData = [
    {
      month: 'Mes 1',
      tasks: [
        { task: 'Sitemap creado', status: 'Completado' },
        { task: 'Cuentas de Google Analytics, Search Console y Business creadas', status: 'Completado' },
        { task: 'Evaluación de SEO inicial', status: 'Completado' },
      ],
    },
    {
      month: 'Mes 2',
      tasks: [
        { task: 'Investigación de palabras clave', status: 'Completado' },
        { task: 'Optimización de contenido', status: 'Completado' },
        { task: 'Mejora de la estructura de enlaces internos', status: 'Completado' },
      ],
    },
    {
      month: 'Mes 3',
      tasks: [
        { task: 'Monitoreo de resultados y ajustes', status: 'Completado' },
        { task: 'Creación de backlinks de calidad', status: 'Completado' },
        { task: 'Revisión y optimización continua', status: 'Completado' },
      ],
    },
    {
      month: 'Mes 4',
      tasks: [
        { task: 'Análisis de competencia', status: 'En curso' },
        { task: 'Creación de contenido especializado (blog/artículos)', status: 'En curso' },
        { task: 'Optimización técnica del sitio (estructura, velocidad, Core Web Vitals)', status: 'En curso' },
      ],
    },
    {
      month: 'Mes 5',
      tasks: [
        { task: 'Desarrollo de estrategia de contenido multimedia (imágenes, videos)', status: 'Pendiente' },
        { task: 'Monitoreo avanzado de palabras clave y rendimiento', status: 'Pendiente' },
        { task: 'Fortalecimiento del perfil de backlinks', status: 'Pendiente' },
      ],
    },
    {
      month: 'Mes 6',
      tasks: [
        { task: 'Revisión de métricas de tráfico y conversiones', status: 'Pendiente' },
        { task: 'Actualización de contenido según tendencias actuales', status: 'Pendiente' },
        { task: 'Planificación de estrategia a largo plazo (SEO sostenible)', status: 'Pendiente' },
      ],
    },
  ];

  const handleNext = () => {
    if (currentMonth < timelineData.length) setCurrentMonth(currentMonth + 1);
  };

  const handlePrevious = () => {
    if (currentMonth > 1) setCurrentMonth(currentMonth - 1);
  };

  return (
    <section className="bg-indigo-100 text-indigo-900 py-16 px-4 text-center w-full">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-4xl font-bold leading-tight mb-4">¡Mira lo que sigue!</h2>
        <p className="text-lg md:text-xl mb-8">
          Estamos comprometidos en posicionar tu sitio en los mejores resultados de búsqueda. Aquí está nuestro plan paso a paso para los próximos meses.
        </p>

        {/* Slider de meses */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h3 className="text-2xl font-bold text-indigo-800 mb-4">{timelineData[currentMonth - 1].month}</h3>
          <ul className="space-y-4 text-left">
            {timelineData[currentMonth - 1].tasks.map((task, index) => (
              <li key={index} className="flex items-center justify-between">
                <span>{task.task}</span>
                <span
                  className={`ml-auto text-xs px-2 py-1 rounded-full ${
                    task.status === 'Completado'
                      ? 'bg-green-100 text-green-700'
                      : task.status === 'En curso'
                      ? 'bg-yellow-100 text-yellow-700'
                      : 'bg-gray-100 text-gray-700'
                  }`}
                >
                  {task.status}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* Botones de navegación */}
        <div className="flex justify-between items-center mt-6">
          <button
            onClick={handlePrevious}
            disabled={currentMonth === 1}
            className={`px-4 py-2 rounded-lg shadow-md ${
              currentMonth === 1
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-indigo-600 text-white hover:bg-indigo-700'
            }`}
          >
            Anterior
          </button>
          <button
            onClick={handleNext}
            disabled={currentMonth === timelineData.length}
            className={`px-4 py-2 rounded-lg shadow-md ${
              currentMonth === timelineData.length
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-indigo-600 text-white hover:bg-indigo-700'
            }`}
          >
            Siguiente
          </button>
        </div>
      </div>

      <p className="mt-12 text-indigo-600 font-semibold">Próximo Reporte: 7 de Febrero 2025</p>
    </section>
  );
};

export default MotivationalSection;
