import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Datos anteriores de las páginas más populares (mes anterior)
const previousPageData = [
  { name: 'My Spanish First – Spanish Learning', views: 35 },
  { name: 'Contact Us – My Spanish First', views: 10 },
  { name: 'FAQs – My Spanish First', views: 10 },
  { name: 'About Us – My Spanish First', views: 7 },
  { name: 'Know your level – My Spanish First', views: 5 },
  { name: 'Test Online – My Spanish First', views: 4 },
];

// Datos actualizados de las páginas más populares (mes actual)
const pageData = [
  { name: 'My Spanish First – Spanish Learning', views: 52 },
  { name: 'Contact Us – My Spanish First', views: 20 },
  { name: 'FAQs – My Spanish First', views: 15 },
  { name: 'About Us – My Spanish First', views: 11 },
  { name: 'Know your level – My Spanish First', views: 9 },
  { name: 'Test Online – My Spanish First', views: 7 },
  { name: 'Online One-On-One Courses – My Spanish First', views: 4 },
];

// Componente de la sección de Páginas Más Populares
const PopularPagesSection = () => {
  return (
    <section className="bg-white py-10 sm:py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl sm:text-3xl font-bold text-center text-gray-800 mb-4 sm:mb-6">Páginas Más Populares</h2>
        <p className="text-base sm:text-lg text-center text-gray-600 mb-8 sm:mb-12">
          A continuación, se muestran las páginas más visitadas de tu sitio web y sus métricas de rendimiento.
        </p>

        {/* Sistema de 2 columnas: Gráfico de barras a la izquierda, lista de páginas a la derecha */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 sm:gap-12 items-start">
          {/* Columna izquierda: Gráfico de barras vertical compuesto */}
          <div className="flex justify-center w-full">
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                layout="vertical"
                data={pageData}
                margin={{
                  top: 5,
                  right: 20,
                  left: 10,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={150} tick={{ fontSize: 12 }} />
                <Tooltip />
                <Legend />
                <Bar dataKey="views" fill="#6366f1" name="Vistas Totales" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          {/* Columna derecha: Lista estilizada de páginas y métricas */}
          <div className="space-y-4">
            <h3 className="text-lg sm:text-xl font-semibold text-gray-800">¿Por qué son importantes estas métricas?</h3>
            <p className="text-gray-600 mb-4 sm:mb-6">
              Las páginas más populares del sitio web indican qué contenidos y secciones atraen más la atención de los visitantes. Analizar las vistas totales y los cambios respecto al mes anterior ayuda a identificar oportunidades para optimizar el contenido y mejorar el rendimiento del sitio.
            </p>
            <div className="space-y-4">
              {pageData.map((page, index) => {
                const previousViews = previousPageData.find((prev) => prev.name === page.name)?.views || 0;
                const viewDifference = page.views - previousViews;

                return (
                  <div
                    key={index}
                    className="p-4 border border-indigo-100 rounded-lg hover:bg-indigo-50 transition duration-200"
                  >
                    <p className="text-base sm:text-lg font-semibold text-indigo-700 flex items-center">
                      {page.name}
                      {viewDifference > 0 && (
                        <span className="ml-3 bg-green-100 text-green-800 text-sm font-semibold px-2 py-1 rounded-full">
                          +{viewDifference} más que el mes anterior
                        </span>
                      )}
                      {viewDifference < 0 && (
                        <span className="ml-3 bg-red-100 text-red-800 text-sm font-semibold px-2 py-1 rounded-full">
                          {viewDifference} menos que el mes anterior
                        </span>
                      )}
                    </p>
                    <p className="text-sm sm:text-base text-gray-600">
                      Vistas: {page.views}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PopularPagesSection;
